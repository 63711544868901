body {
  background-color: #d6d7d6;
}
.page-wrapper {
  padding: 15px;
}

.header-bar__nav-header_branding-bar {
  height: 60px;
  width: 100%;
  position: relative;
  z-index: 900;
  background-color: #f2f3f3;
  border-bottom: 1px solid transparent;
}

.header-bar__nav-header_logo2 {
  height: 28px;
  width: auto;
}

.honda_logo {
  height: 100px;
  width: auto;
  padding-right: 20px;
}
.inqiry-ssn {
  padding-left: 0px;
}
.lender-support-at {
  padding-top: 18px;
}
.verify-panel-table {
  margin-top: 20px;
}
.footer {
  padding-right: 15px;
}
.margin-top-15-button {
  margin-top: 15px;
}
.payoff-inst-title-label {
  margin-top: 5px;
}
.payoff-inst-title-value{
  margin-top: 5px;
}
.blank-line {
 border-bottom: 1px dotted;
}
.top-error-message{
  margin-top: 10px;
}
.agreement-required {
    display: inline-block;
    content: "*";
    color: #dd2b2b;
    margin-left: 4px;
}
.payoff-doc-icon {
  vertical-align: bottom;
}
.cx-radios .disabled .cx-radio__label {
    color: #b2b6bf;
}
